'use client';

import { clearTokens, getTokens, saveTokens } from 'helpers/tokens';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useCallback, useState } from 'react';
import { refreshTokens } from 'helpers/api';
import { useRouter } from 'next/navigation';
import { ApiError } from 'generated/api';

export const Providers = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const [refreshingToken, setRefreshingToken] = useState(false);

  // eslint-disable-next-line react/hook-use-state
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 10_000,
            retry: (failureCount, error) => {
              // Don't retry for 401 error responses
              if (error instanceof ApiError && [401].includes(error.status)) {
                return false;
              }

              // Retry others just once
              return failureCount <= 1;
            },
          },
        },
        queryCache: new QueryCache({
          onError: async (error) => {
            // If there is a authentication error, refresh the token and retry all queries when it succeeds
            if (error instanceof ApiError && [401].includes(error.status)) {
              await refreshAuthToken();
            }
          },
        }),
      })
  );

  const refreshAuthToken = useCallback(async () => {
    const { refreshToken } = getTokens();

    if (!refreshingToken) {
      if (refreshToken) {
        try {
          setRefreshingToken(true);
          const response = await refreshTokens({ refreshToken });
          saveTokens(response.accessToken, response.refreshToken);
          await queryClient.invalidateQueries();
        } catch {
          // If refreshing token fails, clear and redirect back to login
          clearTokens();
          await queryClient.invalidateQueries();
          router.replace('/login');
        } finally {
          setRefreshingToken(false);
        }
      } else {
        // If no refresh token, clear and redirect back to login
        clearTokens();
        await queryClient.invalidateQueries();
        router.replace('/login');
      }
    }
  }, [queryClient, refreshingToken, router]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
